import axios from '../axios'
import { User } from '../types'

async function list(query, page, perPage, orderBy, orderType): Promise<User[]> {
  const params = { query, page, perPage, orderBy, orderType }
  const response = await axios.get('/admin/users', { params })
  return response.data
}

export default {
  list
}
