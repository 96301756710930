import * as React from 'react'
import { useEffect, useState } from 'react';
import Loading from './components/Loading'
import { Link } from 'react-router-dom'
import { useFetchData } from './hooks'
import usersService from './services/users'
import { AdminUsersResponse, User } from './types'
import ReactPaginate from 'react-paginate';
import * as moment from 'moment'

const ROWS_PER_PAGE = 50

export default () => {
  const [pageCount, setPageCount] = useState(0);
  const [page, setPage] = useState(1);
  const [query, setQuery] = useState("");
  const [queryUpdated, setQueryUpdated] = useState(false); // a flag used to update the data after query changes
  const [orderBy, setOrderBy] = useState("createdAt");
  const [orderType, setOrderType] = useState("desc");

  const [{ loading, error, data }] = useFetchData<AdminUsersResponse>(() => {
    return usersService.list(query.length > 2 ? query : null, page, ROWS_PER_PAGE, orderBy, orderType);
  }, [queryUpdated, page, orderBy, orderType]);

  useEffect(() => {
    if (data?.usersCount) {
      setPageCount(Math.ceil(data?.usersCount / ROWS_PER_PAGE));
    }
  }, [data]);

  const updateData = (e) => {
    e.preventDefault()
    setQueryUpdated(queryUpdated => !queryUpdated);
  };

  const changePage = (page) => {
    setPage(page.selected + 1)
  };

  const changeOrderBy = (value: string) => {
    if (orderBy === value) {
      setOrderType(orderType => orderType === "asc" ? "desc" : "asc")
    } else {
      setOrderBy(value)
      setOrderType("asc")
    }
  };

  const renderTh = (column, title) => {
    return (
      <th style={{ cursor: "pointer" }} onClick={() => changeOrderBy(column)}>
        <div className="with-order">
          {title}
          <div className="order-arrows">
            <div className={`order-asc ${orderBy === column && orderType === "asc" ? "order-active" : ""}`}></div>
            <div className={`order-desc ${orderBy === column && orderType === "desc" ? "order-active" : ""}`}></div>
          </div>
        </div>
      </th>
    )
  };

  if (loading) return <Loading />
  if (error) return <p>Error :(</p>

  const renderPagination = () => {
    return (
      <ReactPaginate
          breakLabel="..."
          nextLabel=">"
          onPageChange={changePage}
          pageRangeDisplayed={1}
          pageCount={pageCount}
          previousLabel="<"
          className="pagination"
          activeLinkClassName="active"
          forcePage={page - 1}
        />
    )
  };

  return (
    <div className="admin-users-page">
      <header>
        <h1>Usuarios</h1>
        <div>
          <Link to="/admin/invitations" className="pending-invitations">{data?.invitationsCount} invitaciones pendientes</Link>
          <Link to="/admin/invitations/new" className="btn btn-primary">Invitar</Link>
        </div>
      </header>

      <div className="table-above">
        <form className="filters" onSubmit={updateData}>
          <input type="text" value={query} placeholder="Buscar email o nombres" onChange={e => setQuery(e.target.value)} />
        </form>
        { renderPagination() }
      </div>
      
      <table className="table">
        <thead>
          <tr>
            <th>Id</th>
            <th>Nombre</th>
            <th>Email</th>
            { renderTh("createdAt", "Fecha Registro") }
            { renderTh("lastActivity", "Último Ingreso") }
            { renderTh("progress", "Progreso") }
          </tr>
        </thead>
        <tbody>
          { data && data.users.map((u: User) => (
            <tr key={u.id}>
              <td>{u.id}</td>
              <td>{u.firstName} {u.lastName}</td>
              <td>{u.email}</td>
              <td>{moment(u.createdAt).format('DD/MM/YYYY HH:mm')}</td>
              <td>{u.lastActivity ? moment(u.lastActivity).format('DD/MM/YYYY HH:mm') : ""}</td>
              <td>
                <div className="section-progress">
                  <div className="progress-bar" style={{ width: `${u.progress ? u.progress : 0}%` }}></div>
                  <span style={{ zIndex: 1 }}>{u.progress ? Math.round(u.progress) : 0}%</span>
                </div>
              </td>
            </tr>
          )) }
        </tbody>
      </table>
      <div className="table-below">
        <div>{data.usersCount} registros</div>
        { renderPagination() }
      </div>
      
    </div>
  )
}
